.normalView{
  display: none;
}

/* Media query for mobile view */
@media screen and (max-width: 500px) {
    .mobileView{
        display: none;
    }
}

/* Media query for mobile view */
@media screen and (max-width: 710px) {
    .mobileViewLogo{
        display: block;
    }
    .mobileViewLogo2{
        display: none;
    }
}