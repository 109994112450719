.selection {
  display: flex;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buttons-search {
  display: flex;
  align-items: center;
  gap: 6px;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Media query for mobile view */
@media screen and (max-width: 800px) {
  .selection {
    display: block;
  }
  .officeBttn {
    margin-top: 5px;
  }
  .bottom {
    display: block;
    text-align: center;
  }
  .flex-container {
    display: block;
    text-align: center;
    margin: 4px 0;
  }
  .buttons-search {
    display: block;
    text-align: center;
    margin: 4px 0;
  }
}
